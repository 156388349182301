@import '../scss/vars.scss';

.pageContainer {
  width: 12 * $col-width;
  margin: 0 auto;
}

.topNav {
  height: 0.5 * $row-height;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid $divider-color;
  margin-bottom: 1rem;
  .logo {
    text-align: left;
    width: 24rem;
    line-height: 0.5 * $row-height;
    h1 {
      font-weight: 200;
    }
  }
  .accountNav {
    line-height: 0.5 * $row-height;

    >span {
      margin: 0 0.5rem;
    }

    img {
      vertical-align: middle;
      border-radius: 3px;
      margin-right: 0.5rem;
    }
  }
}

@import '../scss/vars.scss';

.checkboxCol {
  width: 2rem;
}

.timeCol {
  width: 12rem;
}

.threadCol {
  width: 15rem
}

.rowCheckbox {
  vertical-align: baseline;
}

input[type=number] {
  width: 4rem;
  text-align: right;
  font-size: 1.4rem;
}

.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

@import '../scss/vars.scss';

.mainContent {
  margin-left: $col-width/4;
}

.emailList {
  list-style: none;
  margin-left: $col-width/4;
}

.deleteEmail {
  border: none;
}

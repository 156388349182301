@import './vars.scss';

.formContainer {
  margin: 25vh auto;
  width: 6 * $col-width;

  h1 {
    text-align: center;
    line-height: 0.5 * $row-height;
    margin-bottom: 0.5 * $row-height;
  }

  .formRow {
    height: 0.5 * $row-height;

    label {
      display: inline-block;
      width: 2 * $col-width;
      text-align: right;
    }

    input {
      margin: auto 1rem;
      font-size: 1.6rem;
    }

    input[type=email],input[type=password] {
      border: none;
      border-bottom: 1px solid black;
      width: 3 * $col-width - 2rem;
    }

    input[type=submit] {
      margin-left: 2 * $col-width + 1rem;
    }
  }

  .error {
    color: red;
    border: solid 0.5px red;
    margin: 1rem 0;
    padding: 0.5rem;
  }
}

@import './vars.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
}

h2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  cursor: pointer;
  color: $link-color;
  text-decoration: none;
}

a:visited {
  color: $link-color;
}

input[type=button],input[type=submit] {
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

input[type=submit] {
  margin-left: 2 * $col-width + 1rem;
  width: $col-width;
  height: 0.5 * $row-height;
  color: white;
  background-color: $link-color;
}

.error {
  color: $error-foreground;
  background-color: $error-background;
}

p {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

button {
  padding: 0.5rem;
  border-radius: 4px;
  height: 2.5rem;
  font-size: 1.2rem;
  cursor: pointer;
}

input {
  height: 2.5rem;
  border-width: 0 0 1px 0;
  border-bottom: 1px solid black;
  font-size: 1.6rem;
}

thead {
  font-weight: bold;
}

td {
  padding: 0.5rem 0.5rem;
}

tr:nth-child(even) {
  background-color: $alt-background-color;
}

a.active {
  color: $active-link-color;
}

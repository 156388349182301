@import '../scss/vars.scss';

.sitePage {
  display: flex;

  .sidebar {
    flex: 0 0 (2 * $col-width);
    ul {
      list-style: none;
      text-align: right;
      padding-right: 1rem;
      li {
        margin-bottom: 1rem;
      }
    }
  }

  .main {
    flex-basis: 10 * $col-width;
    table {
      width: 100%;
    }
  }
}
